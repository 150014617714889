import React from "react"
import Layout from "../components/layout/Layout"
import { Pages } from "../pages"

export default () => (
  <Layout page={Pages.ERROR}>
    <div
      className="text-center"
      style={{
        height: "100%",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h1>Page not Found!</h1>
    </div>
  </Layout>
)
